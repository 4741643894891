//////////////////////////////////////////////
//! Modal VIEW 
//////////////////////////////////////////////
ModalView = function(dispatcher ) {

//--------------------------------------------
//! Static Vars
//--------------------------------------------
	this.wrapper = dom.modal.wrapper;
	this.content = dom.modal.content;
	this.shower = dom.links.modal.open;
	this.hider = dom.links.modal.close;
	
//--------------------------------------------
//! Vars
//--------------------------------------------
	this.isOpen = false;
	this.current = '';
	this.type = '';


//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////

};

ModalView.prototype = {


	constructor: ModalView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init : function() {
		
		// Start Events
		this.bindEvents();
		
	},

	/*---------------------------------------------------
		EVENT HANDLERS 
	--------------------------------------------------*/
	
	bindEvents: function() {
		
		// LUSTEN FOR VID EVENTS HERE
		app.dispatcher.addEventListener(EVENTS.VIDEO.END, this.hide.bindX(this));
		
		//Binding for page change links
		$(this.shower).click(function(e){ e.preventDefault();
			var target = e.currentTarget;
			
			//Fetch the content
			this.show($(target).attr('href'));
			
		}.bindX(this));
		
		// Binding for CLose button
		$(this.hider).click(function(e){ e.preventDefault();
			
			//Close the modal
			this.hide();
			
		}.bindX(this));
		
	},
	
	/*---------------------------------------------------
		FUNCTIONS 
	--------------------------------------------------*/
	// Fetch the content
	getContent:function(content) {
		
		// Check if video
		if (content === 'video') {
			
			//Asign YouTube Video Holder
			this.current = $(dom.modal.videos);
			this.type = 'video';
			
		} else {

			//Strip php tags
			var contentName = content.replace('.php','');
			
			//Find Content element and store
			this.current = $('#js-modal '+contentName);
			this.type = 'html';
		}
		
	},
	
	show:function(content){
	
		
		app.dispatcher.dispatch(EVENTS.MODAL.OPENING, this.type);
		
		//Log modal as open
		this.isOpen = true;
		
		// Get the Content
		this.getContent(content);
		
		//Begin Animation
		app.ani.modalOpen(this.current);
		
		
	},
	
	hide: function(event) {
		
		this.isOpen = false;
		
		app.dispatcher.dispatch(EVENTS.MODAL.CLOSING, this.type);
		
		app.ani.modalClose(this.current);
		
		this.current = '';
		
		app.util.lockScroll(false);
	},
};
