//////////////////////////////////////////////
//! Utilities VIEW 
//////////////////////////////////////////////
UtilitiesView = function(dispatcher) {
	
//--------------------------------------------
//! Static Vars
//--------------------------------------------
	this.today = new Date();
	this.release = new Date($(body).attr('data-releaseDate'));
	
//--------------------------------------------
//! Vars
//--------------------------------------------

	this.scrollLocked = function(e){e.preventDefault();e.stopPropagation();return false;};
	this.scrollPos = 0;

//////////////////////////////////////////////

//////////////////////////////////////////////

};

UtilitiesView.prototype = {

	constructor: UtilitiesView,

	/*---------------------------------------------------
		FUNCTIONS 
	--------------------------------------------------*/
	
	//Easily toggle CSS .is-xxx clases for states
	toggleState:function(ele, class1, class2) {
		
		//Toggle single class on or off
		if(!class2) {
			
			//Class apply
			if(!$(ele).hasClass(class1)) {
				$(ele).addClass(class1);
			
			//Class remove
			} else {
				$(ele).removeClass(class1);
			}
		
		// Toggle between multiple classes
		} else {
			
			// If no class applied add class1
			if((!$(ele).hasClass(class1))||(!$(ele).hasClass(class2))) {
				$(ele).addClass(class1);
			
			//If class one is applied add class2
			} else if ($(ele).hasClass(class1)) {
				$(ele).removeClass(class1);
				$(ele).addClass(class2);
			
			// If class2 applied add class2
			} else if ($(ele).hasClass(class2)) {
				$(ele).removeClass(class2);
				$(ele).addClass(class1);
			}
		}
	},
	
	releaseChecker: function(preClass, postClass) {
		
		// Released
		if (this.release <= this.today) {
			
			$('.'+preClass).remove();
		} else {
			$('.'+postClass).remove();
		}
		
	},
	
	lockScroll:function(bool) {
		
		
			
			if (bool === true) {
				document.addEventListener("touchmove", this.scrollLocked, false);
				
				//alert('LOCKED');
			} else {
				document.removeEventListener("touchmove", this.scrollLocked, false);
				//alert('UNLOCKED');
			}
		
		
	},
	
		slickSlider:function(el, settings){
		//alert();\
		//console.log(settings);
		var track = el.replace('#','').replace('.','').replace('-','').replace('_','');
		if (!app.sliders[track]) {
			$(el).slick(settings);
			app.sliders[track] = true;
		}
	
	},
	
};