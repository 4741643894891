//////////////////////////////////////////////
//! Gallery VIEW 
	// Handles all functions and events to do with gallery page
//////////////////////////////////////////////

GalleryView = function(settings, dispatcher) {

//--------------------------------------------
//! Static Vars
//--------------------------------------------

	this.posts = [];
	this.settings = settings;
	this.blogUrl = $('body').attr('data-blogUrl');
	this.blogTitle = $('body').attr('data-blogTitle');
//--------------------------------------------
//! Vars
//--------------------------------------------
	//sharing storage
	this.currId = null;
	this.currShare = null;
	
	// gallery
	this.current = 0;
	this.currentId = $;
	this.total = 0;
	this.animating = false;

	//Swipe
	this.touchstartX = 0;
	this.touchendX = 0;
	this.touchstartY = 0;
	this.touchendY = 0;

//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////

};

GalleryView.prototype = {

	constructor: GalleryView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init : function() {
		//console.log('GALLERY INIT')
		
		this.getData(this.build.bindX(this));
		// Start Events
		this.bindEvents();
		
		
	},

	/*---------------------------------------------------
		EVENT HANDLERS 
	--------------------------------------------------*/
	bindEvents: function(){
		
		// Posts
		//app.dispatcher.addEventListener(EVENTS.TUMBLR.POSTSREADY, this.build.bindX(this));
		
		
		$(this.settings.next).click(function(){

				this.next(true);

		}.bindX(this));
		
		$(this.settings.prev).click(function(){
			
				this.next(false);
			
		}.bindX(this));
		
		// Swipe gestures
		document.getElementById(this.settings.el.replace('#', '')).addEventListener('touchstart', function(event) {
			this.touchstartX = event.changedTouches[0].screenX;
			this.touchstartY = event.changedTouches[0].screenY;
		}.bindX(this), false);
		
		document.getElementById(this.settings.el.replace('#', '')).addEventListener('touchend', function(event) {
			this.touchendX = event.changedTouches[0].screenX;
			this.touchendY = event.changedTouches[0].screenY;
			
			
			var xdiff = Math.abs(this.touchendX - this.touchstartX);
			var ydiff = Math.abs(this.touchendY - this.touchstartY);
			
			if ((this.touchendX < this.touchstartX) && (xdiff > 50) && (ydiff < 50)) {
				
				this.next(true);
				
			} else if ((this.touchendX > this.touchstartX) && (xdiff > 50) && (ydiff < 50)) {
				
				this.next(false);
			}
			
		}.bindX(this), false);
	
		// PAGE EVTNTS
		
		app.dispatcher.addEventListener(EVENTS.PAGE.NEWLOAD, function(e,d){
			
			switch (d){
				case 'gallery':
					this.updateSocial();
					break;
			}

		}.bindX(this));
		
		app.dispatcher.addEventListener(EVENTS.PAGE.RELOAD, function(e,d){
			
			switch (d){
				case 'gallery':
					this.reset();
					this.updateSocial();
					break;
			}

		}.bindX(this));
		
		app.dispatcher.addEventListener(EVENTS.PAGE.CLOSED, function(e,d){
			
			switch (d){
				case 'gallery':
					this.socialClear();
					break;
			}

		}.bindX(this));
		
	},
	
	
	/*---------------------------------------------------
		FUNCTIONS 
	--------------------------------------------------*/
	
	getData:function(callback){
		//console.log('FETCHING DATA');
		
		var galPosts = []; // save posts data
		
		
		var postEls = $('.js-blogPost');
		
		//console.log(postEls);
		
		for (i=0; i < postEls.length; i++) {
			
			var thisPost = postEls.eq(i);
			var tags = thisPost.attr('data-tag');
			
			//if (tags == "gallery") {
			if (tags.toLowerCase().indexOf("gallery") >= 0) {
				
				var data = {
					id: thisPost.attr('data-id'),
					permalink: thisPost.attr('data-perma'),
					reblogUrl: thisPost.attr('data-reblog'),
					caption: thisPost.attr('data-caption'),
					srcDesk: thisPost.attr('data-src-desk'),
					srcMob: thisPost.attr('data-src-mob'),
					like: thisPost.children('.pst-like').html()
				};
				//console.log(data);
				galPosts.push(data);
				
			}
			
		}
		
		this.posts = galPosts; // push posts to global storeage
		this.total = this.posts.length; // record total number of posts
		
		
		
		callback(this.posts);
	},
	
	
	build: function(posts){
		
		
		
		//console.log('BUILDING GALLERY hi');
		
		var items = $(document.createDocumentFragment()); // storage for content
		
		var css = 'position: absolute; top:0; left:0; width: 100%; height: 100%; transform: translateX(0);visibility:hidden; background-size:contain; background-repeat: no-repeat; background-position: center center;';
		
		var html = '';
		
		
		// Tumblr Image size ref
		var imgSize = 'srcDesk'; // high res
		
		if (Detectizr.device.type == 'mobile') {
			imgSize = 'srcMob'; // 500px
		}
		
		for (i=0; i < posts.length; i++) {
			var post = posts[i]; 
			//console.log()
				
				html += '<li id="js-slide'+post.id+'" data-id="'+post.id+'" class="obj-gallery__slide" style="'+css+' background-image:url('+post[imgSize]+');"><img style="opacity:0; pointer-events:none;" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" /></li>';
				
				
				items.append(html);
				html = ''; //rest html
			
			
		}
		
		$(this.settings.el).append(items); // add all items to DOM
		
		$('.obj-gallery__slide:first-child').css('visibility', 'visible').addClass('slide-current');
		
	},
	
	
	socialClear: function(callback){
		// Check if a share is active in the gallery 
		if (this.currId && this.currShare) {
			
			// replace the sharing html to the original location in blog
			$(dom.blog.post+this.currId+' '+dom.blog.share).html(this.currShare); 
			
			// Remove the share html form the gallery
			$('#js-slide'+this.currId+' '+dom.blog.share).html('');
			
			// Reset the values
			this.currId = null; this.currShare = null;
		}
		
		app.refreshLikeBtn();
		
		if(callback) {
			callback();
		}
		
/*
		$('.btn-like').mouseover(function(){
			
			$(this).addClass('hover');
		})
		
		$('.btn-like').mouseout(function(){
			$(this).removeClass('hover');
		})
*/
		
	},
	
	updateSocial: function(){
		
		
		// UPDATE the current slidees POST ID
		this.currId = $(this.settings.el).children().eq(this.current).attr('data-id');
		
		//console.log(this.currId);
		// Get the HTML form related blogs posts shareing nav
		//console.log( $(dom.blog.post+this.currId+' '+dom.blog.share).html());
		this.currShare = $(dom.blog.post+this.currId+' '+dom.blog.share).html();
		
		

		
		//console.log(this.currId);
		
		// Remove the related blog posts sharing
		$(dom.blog.post+this.currId+' '+dom.blog.share).html('');
		
		//console.log($(dom.blog.post+this.currId+' '+dom.blog.share).html());
		
		// Add the new sharing data to the gallery
		$(this.settings.share).html(this.currShare);
		
		
		app.refreshLikeBtn();

	},
	
	next:function(dir) {
		
		if (!this.animating) { // Block action if currently animating
			//alert('next');
			
			// Slide Counting
		
			var c = this.current + 1; // 0 to 1
			var n;
			
			// Direction slide values
			var din;
			var dout;
			
			
			
			if (dir) {  // NEXT
				
				if (c == this.total) {
					n = 1;
				} else {
					n = this.current + 2; // 0 to 2
				}
				
				din = '100%';
				dout = '-100%';
			
			} else { // PREVIOUS
				
				if (c == 1) {
					n = this.total;
				} else {
					n = c - 1;
				}
				
				din = '-100%';
				dout = '100%';
				
			}
			
			this.animating = true;
			
			var next = new TimelineMax({delay:0, repeat:0, onComplete:function(){
				
				//console.log('c='+c+' n='+n);
				
				
				
				this.current = n - 1;
				
				this.socialClear(this.updateSocial.bindX(this)); // Clear and update the social content
				
				this.animating = false;
				
				
				
			}.bindX(this)})
				
				.set($(this.settings.el+' li:nth-child('+n+')'), {'visibility':'visible', x:din})
				.fromTo($(this.settings.el+' li:nth-child('+c+')'), this.settings.speed, {x:'0%', ease:Power4.easeOut}, {x:dout, ease:Power4.easeOut}, 'slide')
				.fromTo($(this.settings.el+' li:nth-child('+n+')'), this.settings.speed, {x:din, ease:Power4.easeOut}, {x:'0%', ease:Power4.easeOut}, 'slide')
				.set($(this.settings.el+' li:nth-child('+c+')'), {'visibility':'hidden', x:'0%'})
			;
		}
	},
	
	reset:function(e){
		
		TweenMax.set('.'+this.settings.class+'__slide', {'visibility':'hidden', onComplete: function(){
			TweenMax.set(this.settings.el+' li:first-child',{x:'0%', 'visibility':'visible'});
			this.current = 0;
		}.bindX(this)});
		
		
		
	}
/*
	go: function(slide){
		$(this.settings.class+'__slide')
	},
	
	
*/
	
};