//////////////////////////////////////////////
//! Videos VIEW 
//////////////////////////////////////////////
VideosView = function(dispatcher) {

//--------------------------------------------
//! Static Vars
//--------------------------------------------
	
	this.vidOpen = dom.links.video;
	this.plistOpen = dom.links.playlist;
	
	
	this.trailer = {
		id: $('body').attr('data-trailerID'), // Trailer default,
		show: false, // Check if the trailer should be shown automatically or not
		watched: false, //Has the auto trailer been watched?
		
	};
	
	this.settings = {
		containerID : dom.video.player,//MANDATORY
		id : $('body').attr('data-trailerID'),//MANDATORY
		closeBtn : dom.links.modal.close,//MANDATORY
		list: true,
		index : 0 , //OPTIONAL
		showinfo : 0, // OPTIONAL
		autoplay : 0, // OPTIONAL
		controls : 1,
		customControls : settings.videos.customControls,
		customBackground : false,
		playsinline: true,
	};
	
//--------------------------------------------
//! Vars
//--------------------------------------------
	this.isPlaying = false; // Tracking
	this.uipaused = false;
	this.ytpaused = false;
	this.watching= $('body').attr('data-trailerID'); // Video is currently loaded
	this.updateTime = '';
	this.pos = 0;
	this.watchingList = false;

//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////

};

VideosView.prototype = {


	constructor: VideosView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init: function() { //alert('vids');
		
		//Setup Dispatcher
		this.settings.dispatcher = app.dispatcher;

		// Deactivate autoplay on mobile tablet and pages other than default
		if (Detectizr.device.type == 'desktop' && app.page.current === 'home' && app.trailerAuto == 'true') {
			this.settings.autoplay = 0;
		}
		
		//Add a new palyer
		this.video = new YTVID(this.settings);
		this.video.add();
		this.video.stop();
		// Start Events
		this.bindEvents();
		
	},

	/*---------------------------------------------------
		EVENT HANDLERS 
	--------------------------------------------------*/
	
	bindEvents: function() {
		
		//STATE CHANGES
		app.dispatcher.addEventListener(EVENTS.VIDEO.STATES, this.states.bindX(this));
		
		//PLAY vids ON
		app.dispatcher.addEventListener(EVENTS.MODAL.VIDOPEN, this.play.bindX(this));
		
		//STOP vids ON
		app.dispatcher.addEventListener(EVENTS.MODAL.CLOSING, this.stop.bindX(this));
		
		//LOAD VID
		app.dispatcher.addEventListener(EVENTS.VIDEO.LOAD, this.load.bindX(this));
		
		// UI Btn
		$(this.vidOpen).click(function(e){ e.preventDefault();
			var target = e.currentTarget;
			
			this.load(null, $(target).attr('href'), false);
			
		}.bindX(this));
		
		$(this.plistOpen).click(function(e){ e.preventDefault();
			var target = e.currentTarget;
			
			this.load(null, $(target).attr('href'), true);
			
		}.bindX(this));
		
		
		
		
		app.dispatcher.addEventListener(EVENTS.WINDOW.BLUR, this.pauseToggle.bindX(this));
		
		app.dispatcher.addEventListener(EVENTS.WINDOW.FOCUS, this.pauseToggle.bindX(this));
		
	},
	
	/*---------------------------------------------------
		FUNCTIONS 
	--------------------------------------------------*/
	
	load: function(event, vidID, playlist) { //alert('LOADING VIDEO id: '+id);
		

		
		//Check for trailer shortcode and replace with correct ID
		if (vidID === 'trailer') {
			
			vidID = this.trailer.id; // Get the trailer ID
			
			this.trailer.watched = true; // Track trailer as watched
			
		}
		
		//Check that the ID is not already loaded
		if ( vidID != this.watching) {
			
			//Desktop / Touch device detection
			if(Detectizr.device.type == "desktop") {
				
				//Load a video
				if (!playlist) {

					this.video.player.loadVideoById(vidID);
					this.watchingList = false;
				} else {

					this.video.player.loadPlaylist( { list :  vidID , listType : 'playlist' } );
					this.watchingList = true;
				}
			} else {
				
				if (!playlist) {//Load a video
					this.video.player.cueVideoById(vidID);
					this.watchingList = false;
				} else {
					this.video.player.cuePlaylist({ list :  vidID , listType : 'playlist' });
					this.watchingList = true;
				}
			}
			
			//Update the tracker
			this.watching = vidID;
		}
		
			
		//Open the modal
		app.modal.show('video');
		
	},
	
	//Play current video
	play: function(event) {
		this.video.unmute();
		if (Detectizr.device.type == "desktop") {
			this.video.play();//Play
		}
	},
	
	//Stop Current Video
	stop: function(event) {

		this.isPlaying = false;//track
		this.video.stop();//stop
		if (this.settings.customControls) { clearInterval(this.updateTime);}
	},
	
	pauseToggle: function(){
		
		if (this.isPlaying && this.uipaused) {
			this.video.play();
			this.uipaused = false;
		} else if (this.isPlaying && !this.uipaused) {
			this.video.pause();
			this.uipaused = true;
		}
	},
	
	// Detect a YT Video State change
	states: function(event, data) {
		
		switch (data) {
			
			case -1:// Unstarted
				break;
			
			case 0: // Ended
				if (!this.watchingList) {
					app.dispatcher.dispatch(EVENTS.VIDEO.END); // Close Modal
				}
				
				
				
				break;
			
			case 1: // Playing
				this.ytpaused = false;
				app.dispatcher.dispatch(EVENTS.VIDEO.PLAYING);
				this.isPlaying = true; //track
				
				
				
				
				
				
				break;
			
			case 2: // paused

				
				
				if (this.settings.customControls) { clearInterval(this.updateTime); }
				break;
			
			case 3: // bufffering
				
				
				
				
				
				
				break;
				
			case 5: // Video Cued
				break;
		}
	},
	
};
