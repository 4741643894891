//////////////////////////////////////////////
//! TUMBLR VIEW
	// API CALLS TO TUMBLR FOR POST DATA
//////////////////////////////////////////////

TumblrView = function(dispatcher) {
	
//--------------------------------------------
//! - Static Vars
//--------------------------------------------
	this.tumblerApi = {
		//Tumblr Settings
		tumblrStart: 'https://api.tumblr.com/v2/blog/www.',
		tumblrName: 'tommytesting',
		tumblrEnd: '.tumblr.com/posts/',
		apiKey :'?api_key=78cEx8ppazGUCNOnXcp9Pa0ymXxF0QNk0LGMSua4mBSpKIHS4m'
	};
	
	this.blogTitle = $('body').attr('data-blogTitle');
//--------------------------------------------
//! - Sets
//--------------------------------------------
	this.posts = [];
	this.total = 0;

//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////
	
};

TumblrView.prototype = {


	constructor: TumblrView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init : function() {
		
		this.getPosts();
	},
	
	getPosts:function(callback) {
		//console.log('GETTING TUMBLR DATA')
		var getpostsURL = this.tumblerApi.tumblrStart + this.tumblerApi.tumblrName + this.tumblerApi.tumblrEnd + this.tumblerApi.apiKey;
		
		console.log(getpostsURL);
		
		this.getData(getpostsURL, function(data){

			this.posts = data.response.posts;
			this.total = data.response.posts.length; // record total number of posts
			//console.log(this.posts);
			app.dispatcher.dispatch(EVENTS.TUMBLR.POSTSREADY, this.posts);
			
		}.bindX(this));
	},
	
	getData:function(path, callback){
		$.when($.ajax({
			url: path,
			type: 'GET',
			dataType:'jsonp',
		}).done(function(data) {
			// _data = data;
		}).fail(function() {
			alert('Error from API - Please Try Again Later');
		}).always(function() {
		}))
		// on AJAX finish
		.then(function(data){
			callback(data);
		});
	},
	
	refreshLikes: function(){
		
		var ids = [];
		
		for (i = 0; i < this.posts.length; i++) {
			var post = this.posts[i];
			ids.push(post.id);
		}
		console.log(ids);
		Tumblr.LikeButton.get_status_by_post_ids(ids);
	}
	
/*
	buildGallery: function() {
		console.log('BUILDING GALLERY');
		
		var items = $(document.createDocumentFragment()); // storage for content
		
		var css = 'position: absolute; top:0; left:0; width: 100%; height: 100%; transform: translateX(0);visibility:hidden; background-size:contain; background-repeat: no-repeat; background-position: center center;'
		
		var html = '';
		
		
		// Tumblr Image size ref
		var imgSize = 0; // high res
		
		if (Detectizr.device.type == 'mobile') {
			imgSize = 2; // 500px
		}
		
		for (i=0; i<this.total; i++) {
			var post = this.posts[i]; 
			
			
			console.log(post.tags);
			if ($.inArray('gallery', post.tags) !== -1) { 
				console.log('post '+i+' has tag')
				
				html += '<li data-id="'+post.id+'" class="obj-gallery__slide" style="'+css+' background-image:url('+post.photos[0].alt_sizes[imgSize].url+');"><img style="opacity:0; pointer-events:none;" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="" /></li>';
				
				
				items.append(html);
				html = ''; //rest html
			}
			
		}
		
		$('#js-gallery').append(items); // add all items to DOM
		
		$('.obj-gallery__slide:first-child').css('visibility', 'visible');
		
		
		
	},
*/
	
};