app = {
	
//////////////////////////////////////////////
//! Dispatcher
//////////////////////////////////////////////
	dispatcher: new Dispatcher(),

//////////////////////////////////////////////
//! Settings and Tracking
//////////////////////////////////////////////
	window: {
		w: 0,
		h: 0,
	},
	
	// trailerAuto: $('body').attr('data-trailerShow'),
	trailerAuto: false,
	
	uiLoaded : false, // Check if the Ui is loaded
	
	//Credits
	credits: {
		isOpen: false, // track if credits panel is open/closed
		autoisOpen: false, // track if automatic credits is open/closed
	},
	
	//Mobile Menu
	menuPanal: {
		isOpen: false,
		isAnimating: false,
	},
	
	// Gallery
	galleryMain: {
		el: dom.gallery.el,
		class: 'obj-gallery',
		next: dom.gallery.next,
		prev: dom.gallery.prev,
		speed: 1,
		share: dom.gallery.share,
	},
	

//////////////////////////////////////////////
//! app.init();
//////////////////////////////////////////////
	getTumblr:function(callback) {
		
	},

	//INNIT FUNCTIONS
	init:function(){ //alert('app.init');
		// init Views
		app.ani = new AnimationsView(app.dispatcher); // Animations For site
		app.util = new UtilitiesView(app.dispatcher); // Templpate Utility functions
		app.page = new PagesView(app.dispatcher); // Page Management and changing
		app.videos = new VideosView(app.dispatcher); // Youtube Single Video View
		app.modal = new ModalView(app.dispatcher); // Modal Windows
		
		//;
		//app.tumblr = new TumblrView(app.dispatcher);
		
		app.gallery = new GalleryView(app.galleryMain, app.dispatcher);
		
		// App Functions
		app.util.releaseChecker(dom.release.preClass, dom.release.postClass);// Check objects that are releated to release date
		
		app.bindEvents(); // Start event listeners and click events
		
		app.preloader(2);
		
		//$(".ui-sidebar__footer").css( "display", "none" );

		//app.videoPlaylist();
		
	},

//////////////////////////////////////////////
//! EVENTS
//////////////////////////////////////////////
	
	//DOM Events
	bindEvents:function(){

		app.dispatcher.addEventListener(EVENTS.PAGE.READY, this.getWindowXY.bindX(this));
		// Posts
		//app.dispatcher.addEventListener(EVENTS.TUMBLR.POSTSREADY, this.buildBlog.bindX(this));
		
		// Credits
		$(dom.links.credits).click(function(e){ e.preventDefault();
		
			this.creditsManual();

		}.bindX(this));


		
		// Hide credits when mouse leave (must mouse in first)
		$(dom.ui.credits.el).mouseleave(function() { 
			
			if (this.credits.isOpen) { this.creditsManual(); }
			
		}.bindX(this));
		
		// Hide when interacting with page
		$(dom.pages.el).click(function(){
			
			if (this.credits.isOpen) { this.creditsManual(); }
			
		}.bindX(this));
		
		//Launch Auto Credits
		app.dispatcher.addEventListener(EVENTS.PAGE.UILOADED, this.creditsAuto.bindX(this));


		
		//Menu panal 
		$(dom.links.menuToggle).click(function(e){
			e.preventDefault();
			e.currentTarget = e;
			
			app.menuToggle();
		});
		
		app.dispatcher.addEventListener(EVENTS.PAGE.UILOADED, this.getWindowXY.bindX(this));
		
		// Close menu on
		app.dispatcher.addEventListener(EVENTS.WINDOW.RESIZEWIDTH, this.menuClose.bindX(this));
		app.dispatcher.addEventListener(EVENTS.PAGE.CLOSED, this.menuClose.bindX(this));
		app.dispatcher.addEventListener(EVENTS.MODAL.OPENING, this.menuClose.bindX(this));
		
		// Share menu
		if (Detectizr.browser.name == 'edge' || Detectizr.browser.name == 'ie') {
			$(dom.ui.sharetoggle).click(function(){
				$(dom.ui.sharemenu).toggleClass('is-active');
			});
			
			$('.vp-screensizer').click(function(){
				$(dom.ui.sharemenu).removeClass('is-active');
			});
		}
		
		app.refreshLikeBtn();// Activate hover states for like buttons
		
		$(dom.blog.postClass).mouseover(function(){
			
			$(this).addClass('is-hover');
		});
		
		$(dom.blog.postClass).mouseout(function(){
			$(this).removeClass('is-hover');
		});

	},
	
//////////////////////////////////////////////
//! SITE FUNCTIONS
//////////////////////////////////////////////
	getWindowXY: function() {
		app.window.w = $(window).width();
		app.window.h = $(window).height();
	},
	
	preloader: function(dur) {
				
		var bar = TweenMax.to(dom.preloader.bar, dur, {width:'100%', ease:Linear.easeNone, onComplete: function(){
			
			setTimeout(function(){
				app.dispatcher.dispatch(EVENTS.PRELOADER.FINISHED);
				var out = TweenMax.to(dom.preloader.el, 0.25, {autoAlpha:0, ease:Power4.easeOut, onComplete:function(){
					$(dom.preloader.el).remove();
				}});
			}, 500);
			
		}});
	},
	
	menuToggle: function(e) {
		
		if (!app.menuPanal.isOpen){
			
			app.menuOpen();
			
		} else {
			
			app.menuClose();
		}
		
	},
	
	menuOpen: function(e) {
		
		if((!app.menuPanal.isOpen) && (!app.menuPanal.isAnimating)) {
			app.menuPanal.isOpen = true;
			app.util.lockScroll(true);	
			$(dom.ui.menuPanal).addClass('is-active');
			$(dom.links.menuToggle).addClass('is-active');
			
			app.menuPanal.isAnimating = true;
			this.ani.menuOpen();
		}
	},
	
	menuClose: function(e) {
		
		if ((app.menuPanal.isOpen) && (!app.menuPanal.isAnimating)) {
			app.menuPanal.isOpen = false;
			app.util.lockScroll(false);
			app.menuPanal.isAnimating = true;
			
			this.ani.menuClose();
			
			$(dom.ui.menuPanal).removeClass('is-active');
			$(dom.links.menuToggle).removeClass('is-active');
		}
	},
	
	creditsManual: function(){

		if (app.credits.autoisOpen === false) {
			
			if (!app.credits.isOpen) {

				$(dom.ui.credits.el).addClass('is-active');
				app.credits.isOpen = true;
				
			} else {

				$(dom.ui.credits.el).removeClass('is-active');
				app.credits.isOpen = false;
				
			}
		}
	},
	
	creditsAuto:function(){
		
		// Check for settings
		if (settings.credits.auto) {
			
			// Clone credits ele and attach to body
			$(dom.ui.credits.el).clone().prop('id', dom.ui.credits.autoId).addClass(dom.ui.credits.autoClass).appendTo('body');
			app.credits.autoisOpen = true;
			
			//Delay to make animation work.
			setTimeout(function(){
				$('#'+dom.ui.credits.autoId).addClass('is-active');
			}, 1);

			$(document).on('click','#closebillingblock',function(){
				
				$('#'+dom.ui.credits.autoId).removeClass('is-active');
				
				app.credits.autoisOpen = false;

			});


			// Hide after duration
			setTimeout(function(){
				$('#'+dom.ui.credits.autoId).removeClass('is-active');
				
				app.credits.autoisOpen = false;
				
			}.bindX(this), settings.credits.duration);
		}
		
		
	},
	
	refreshLikeBtn:function(){
		

		// Button fixes (IE11 does noty respect :hover when entering a child iframe)
		
		$('.btn-like').mouseover(function(){
			
			$(this).addClass('is-hover');
		});
		
		$('.btn-like').mouseout(function(){
			
			$(this).removeClass('is-hover');
		});

		
	},

};//END app();


//////////////////////////////////////////////
//! DOC READY and window listeners
//////////////////////////////////////////////

//Doc ready
if (window.canLoad) { //Check Browser is allowed version
	window.onload = app.init;
}

//LISTEN Window Focus

function windowResize(){
	
	var newWidth = $(window).width();
	var newHeight = $(window).height();
	
	app.dispatcher.dispatch(EVENTS.WINDOW.RESIZE); // Global event

	if (newWidth != app.window.w) {app.dispatcher.dispatch(EVENTS.WINDOW.RESIZEWIDTH);} // WIDTH SPECIFIC EVENT
	if (newHeight != app.window.h) {app.dispatcher.dispatch(EVENTS.WINDOW.RESIZEHEIGHT);} // HEIGHT SPECIFIC EVENT
		
	app.getWindowXY();
}

var rs;
$(window).resize(function() {
	clearTimeout(rs);
	rs = setTimeout(windowResize, 250);
});


		
$(document).on("visibilitychange", function() {
	if ( document.hidden ) {
		app.dispatcher.dispatch(EVENTS.WINDOW.BLUR);
	} else {
		app.dispatcher.dispatch(EVENTS.WINDOW.FOCUS);
	}

});

//////////////////////////////////////////////
// SCOPE FUNCTION
//////////////////////////////////////////////
Function.prototype.bindX = function(scope) {
	var _function = this;
	
	return function() {
		return _function.apply(scope, arguments);
	};
	
};
