//////////////////////////////////////////////
//! pages VIEW 
	// Handles all functions and events to do with managing and changing pages
//////////////////////////////////////////////

PagesView = function(dispatcher ) {

//--------------------------------------------
//! Static Vars
//--------------------------------------------
	this.default = settings.pages.default; // get default page for fallback and first load
	this.pageClass = $(dom.pages.el); // Global class for pages
	this.link = $(dom.links.page); // Link ele for pages
	this.popup = $(dom.links.popup); // link ele for popup windows
	this.uiEle = $(dom.ui.class); // UI elements class
	
	
//--------------------------------------------
//! Vars
//--------------------------------------------
	this.visible = false; // Is a page currently displayed?
	this.isAnimating = false; // Is the page animating? (used to lock out new page changes)
	this.current = 'null'; // What is the current page being displayed?
	this.previous = 'null'; // What was the last page displayed?
	this.uiLoaded = false; // Is the User Interface displayed?
	this.viewed = {}; // lists all pages available and tracks if they have been viewed once for events


//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////

};

PagesView.prototype = {

	constructor: PagesView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init : function() {
		///FOOTER HACK
	

		// Start Events
		this.bindEvents();
		
		// Make the pagge viewed list 
		this.makeViewdList();
		
	},

	/*---------------------------------------------------
		EVENT HANDLERS 
	--------------------------------------------------*/
	
	bindEvents: function(){
			
			
			
		
		// CHECK HASH...
		
			//...When location bar changes
			window.onhashchange = function(){ this.checkUrl(null, window.location.hash); }.bindX(this);

			//...When preloader is finished
			app.dispatcher.addEventListener(EVENTS.PRELOADER.FINISHED, this.checkUrl.bindX(this) );
			
		
		// CHECK THE UI
		app.dispatcher.addEventListener(EVENTS.MODAL.CLOSED, this.checkUi.bindX(this) );
		
		// SHOW A PAGE
		
			// When the UI has benn loaded
			app.dispatcher.addEventListener(EVENTS.PAGE.UILOADED, this.pageShow.bindX(this));
		
			// When anoter page has closed
			app.dispatcher.addEventListener(EVENTS.PAGE.CLOSED, this.pageLoad.bindX(this));
		
		// Watch for the end of a pages animation and reset the tracking
		app.dispatcher.addEventListener(EVENTS.PAGE.READY, function(){this.isAnimating = false;}.bindX(this));
		
		// Click link to change HASH
		this.link.click(function(e){
			e.preventDefault();
			var target = e.currentTarget;
			
			// make sue the page isnt already animating
			if (!this.isAnimating) {
			
				// Update the hash to the href
				window.location.hash = $(target).attr('href');
			}
			
		}.bindX(this));
		
		// Binding for Pop Up Links
		this.popup.click(function(e){
			var target = e.currentTarget;
			e.preventDefault();
			
			//Activate pop up window
			this.popupWindows(
				$(target).attr('href'), // Window Url
				$(target).attr('data-name'), // Window Name
				$(target).attr('data-size') // Window Size
			);
			
		}.bindX(this));
		
	},
	
	/*---------------------------------------------------
		FUNCTIONS 
	--------------------------------------------------*/
	

//////////////////////////////////////////////
//! page.list();

	// Generates and tracks a list of pages and whether they have been viewed or not

//////////////////////////////////////////////
	
	//Find all the pages in the root/php/pages/ folder and add a entry on the page.viewed:{} list
	
	makeViewdList:function(src){ //alert('page.makeViewdList();')
		
		var list = {}; // temp strorage
		
		// Cycle Pages
		this.pageClass.each(function(){
			
			// Get the id
			var pageId = $(this).attr('id');
			
			//Add page ID to to list {} list and set to false
 			list[pageId] = false;
			
		});
		
		// Push list to the app
		this.viewed = list;
		
		//console.log(this.viewed);
	},
	
//////////////////////////////////////////////
//! page.checkUrl();

	// Checks a hash tag to see if it exists or defaults to settings.page.default

//////////////////////////////////////////////

	//Check the URL for #hash
	checkUrl:function(event,hash){ //alert('page.checkUrl( '+hash+' );');
		
		// Redirect to home page if tumblr post or tag is selected
		if ( window.location.href.indexOf('post') >= 0 ) {
			window.location.href = "/";
		}
		
		//If no hash entered -> Get Hash from Addressbar
		if(!hash) {hash=window.location.hash;} //Deeplinking for address bar
		
		//console.log(hash);
		
		//Strip hash to form pageId
		var pageId = hash.replace('#','');
		
		//alert('pageId: '+pageId);
		
		// Check there is a Hash in addressbar
		if (location.hash === "") { //alert('THERE IS NO HASH');
			
			//If no Hash update to default
			location.hash = this.default;//Load the default page
		
		//Check the page exisis
		} else if ($(dom.pages.el+'#'+pageId).length === 0) { //alert('PAGE DOESNT EXIST');
			
			//If no page found fall back to default
			location.hash = this.default;//Load the default page
		
		//Check the page is not the current 	
		} else if (pageId !== this.current) { //alert('GETTING NEW PAGE: '+ pageId);
			
			//Update the current page trackers
			this.previous = this.current; // Move current to previous
			this.current = pageId; // Update current to new page
			
			this.change(); // Change The Page
			
			this.updateMenu(hash); // Update menu item classes
			
		}
		
	},

//////////////////////////////////////////////
//! page.change()
	// Decide if a page needs to be closed befor the new one is opened
//////////////////////////////////////////////
	change:function(){
		
		//this.updateMenu();// Update the menu
		
		// Check if there is a page that needs to be hidden...
		if (this.previous != 'null') {
			
			this.pageClose();// Hide the previous page (passes back to page.load(); )
			
		// ...if there is no previous page
		} else {
			
			this.pageLoad(); // Just load the new page
			
		}
		
	},

//////////////////////////////////////////////
//! page.load()
	//Load a page and make decisions about it
//////////////////////////////////////////////

	pageLoad:function(event){ // alert('pageLoad: '+this.current);
		
		app.dispatcher.dispatch(EVENTS.PAGE.LOAD, this.current);
		
		// check if the new page has been viewed before...
		if (this.viewed[this.current]) {
			
			app.dispatcher.dispatch(EVENTS.PAGE.RELOAD, this.current); // SEND LOAD EVENT
			
			this.pageShow(); // SHOW THE PAGE
			
		// ...for new page loads
		} else {
				
			app.dispatcher.dispatch(EVENTS.PAGE.NEWLOAD, this.current); // SEND NEW LOAD EVENT
	
			// Check if Trailer needs to be shown
			if (
				this.current === this.default.replace('#','') && !app.videos.trailer.watched && (app.trailerAuto == 'true')) {
				
				app.dispatcher.dispatch(EVENTS.VIDEO.LOAD, 'trailer');// load the trailer and END THIS
				
				
			// ...if NOT
			} else {
				
				// Check if UI needs to be loaded
				this.checkUi();
			}
		}
	},

//////////////////////////////////////////////
//! page.checkUi();
	// Check if the UI has been loaded
//////////////////////////////////////////////
	
	//Show Ui Elements
	checkUi:function(event, data){ //alert('page.showUi('+page.current+');');
		
		app.dispatcher.dispatch(EVENTS.PAGE.UILOADING);// load the trailer and END THIS
		
		// Check UI Is not already loaded
		if (!this.uiLoaded) {
			
			//Show the UI
			app.ani.ui.seek(0).play();
			
			//Track ui as loaded
			this.uiLoaded = true;
			//this.footerHack();
		} else {
			
			//Show Page
			this.pageShow();
			
		}
	},

//////////////////////////////////////////////
//! page.show();
	// Show a page
//////////////////////////////////////////////
	
	//FOOTER APPEARS AFTER FAKE PRELOAD ,BECAUSE MAIN IMAGE IS NOT BEING PRELOADER SO IT BUMPS UP
	// THIS IS A TEMPORARY HACK THAT SHOULD BE FIXED.We should not fake preload it makes no sense.
	//MY SOLUTION PRELOAD AT LEAST THE MAIN HEADER IMAGE AND THEN CALL SHOW PAGE.

/*
	footerHack : function(){

		setTimeout(function(){
			
			$(".ui-sidebar__footer").css( "display", "block" );
		
		},2000);

	},
*/

	//show selected page
	pageShow:function(event, data) { //alert('page.show('+page.current+');');
		
		// Check No Page is currently loaded
		if (!this.visible) {
			
			this.visible = true;
				
			// Switch the pages over		
			$('#' + this.previous).addClass('is-hidden');
			$('#' + this.current).removeClass('is-hidden');
			
			// Track that this is animating
			this.isAnimating = true;
			
			// Rest Scroll Positions
			$('body').scrollTop(0);
			$('#' + this.current + ' .vp-page__scroll').scrollTop(0);
			
			// choose global or unique Animation
			if (settings.pages.animations.global) {
				
				app.ani.pageGlobalIn(this.current);
				
			} else {
			
				// Find animation to use
				var aniName = this.current + 'In';
				app.ani[aniName].seek(0).play();
			}
		} 
		
	},

//////////////////////////////////////////////
//! page.hide();
	// hide a page
//////////////////////////////////////////////

	//Hide a page
	pageClose:function() { //alert('page.hide('+page.current+');');
		
		// Dispatch Closing Event
		app.dispatcher.dispatch(EVENTS.PAGE.CLOSING, this.previous);
		
		// Track that this is animating
		this.isAnimating = true;
		
		// choose global or unique Animation
		if (settings.pages.animations.global) {
			
			app.ani.pageGlobalOut(this.previous);
			
		} else {
			
			// Find animation to use
			var aniName = this.previous + 'Out';
			app.ani[aniName].seek(0).play();
		}
		
		// Trackers
		this.visible = false;
		this.viewed[this.previous] = true;
	},

//////////////////////////////////////////////
//! PopUp Windows
	// open a pop up window
//////////////////////////////////////////////
//Pop Up window controller
	popupWindows:function(url, name, windowSize) { //alert('app.page.popupWindows: '+url +' / '+ name+' / '+ windowSize)
		
		var newwindow = window.open(url, name, windowSize);
		if (window.focus) {newwindow.focus();}
			return false;	
		},

//////////////////////////////////////////////
//! MENU ITEMS
	// Update the menu items in main menus fo .is-active class
//////////////////////////////////////////////

	updateMenu: function(hash){
		$(dom.pages.menuMain + ' a').removeClass('is-current');
		$(dom.pages.menuMain + ' a[href="'+hash+'"]').addClass('is-current');
	}

};