//////////////////////////////////////////////
//! ANIMATIONS VIEW
	// Stores all of the major animations for the site
//////////////////////////////////////////////

AnimationsView = function(dispatcher ) {
	
	// Reusable var for quick DOM nav within an animation
	this.grp = '';
	
//////////////////////////////////////////////
	this.init();
//////////////////////////////////////////////
	
};

AnimationsView.prototype = {


	constructor: AnimationsView,

	/*---------------------------------------------------
		INITIALIZER
	--------------------------------------------------*/

	init : function() {
		
		// !IMPORTANT - please make sure the Out animations are always written BEFORE the In animations or errors can occur
		

				
		//--------------------------------------------
		//! - Site UI
		//--------------------------------------------
			
			this.grp = dom.ui.class;
			
			this.ui = new TimelineMax({delay:0, repeat:0, onComplete:function(){ app.dispatcher.dispatch(EVENTS.PAGE.UILOADED); $('body').addClass('ui-loaded'); }})
					.set($(this.grp), {display: 'block'})
					.from($(this.grp), 1, {autoAlpha:0, ease:Power4.easeOut})
					.set($(this.grp),{clearProps:'all'})
				.seek(0).pause();
		
		//--------------------------------------------
		//! - Modal Window
		//--------------------------------------------
		
	},
	
	pageGlobalIn:function(grp) {

		globalIn = new TimelineMax({delay:0, repeat:0, onComplete:function(){ app.dispatcher.dispatch(EVENTS.PAGE.READY, app.page.current); }})
			.set($(this.grp),{delay: 0.05, onComplete:function(){$('.obj-contentBar').scrollTop(0);$('.pg-home').scrollTop(0);}}) // delay to fix firefox que issue
			.fromTo($('#'+grp), 1, {autoAlpha:0},{autoAlpha:1, force3D:true, ease:Power4.easeOut})
				
		;
	},
	
	pageGlobalOut:function(grp) {
		
		globalOut = new TimelineMax({delay:0, repeat:0, onComplete:function(){ app.dispatcher.dispatch(EVENTS.PAGE.CLOSED, app.page.previous); }})
					
			.fromTo($('#'+grp), 1, {autoAlpha:1},{autoAlpha:0, force3D:true, ease:Power4.easeOut})
			
		;
	},
	
	menuOpen: function(){
		
		open = new TimelineMax({delay:0, repeat:0, onComplete: function(){ app.menuPanal.isAnimating = false;}})
			.set($(dom.ui.menuPanal),{display:'flex'})
			.fromTo($(dom.ui.menuPanal), 1, {autoAlpha:0},{autoAlpha:1, force3D:true, ease:Power4.easeOut})
		;
		
	},
	
	menuClose: function(){
		close = new TimelineMax({delay:0, repeat:0, onComplete: function(){ app.menuPanal.isAnimating = false;}})
			.fromTo($(dom.ui.menuPanal), 1, {autoAlpha:1},{autoAlpha:0, force3D:true, ease:Power4.easeOut})
			.set($(dom.ui.menuPanal),{display:'none'})
		;
	},
	
	modalOpen:function(content) {
		
		open = new TimelineMax({delay:0, repeat:0, onComplete:function(){ 
			
			if (app.modal.type == 'video') { app.dispatcher.dispatch(EVENTS.MODAL.VIDOPEN);}
			else { app.dispatcher.dispatch(EVENTS.MODAL.READY); }
			
			app.util.lockScroll(true);
			
		}})
			.set($(dom.modal.wrapper),{display:'block'})
			.set($(content),{display:'block'})
			.fromTo($(dom.modal.wrapper), 1, {autoAlpha:0},{autoAlpha:1, force3D:true, ease:Power4.easeOut}, 'modal')
			.fromTo($(content), 0.5, {autoAlpha:0},{autoAlpha:1, force3D:true, ease:Power4.easeOut, delay:0.5}, 'modal')
		;
		
	},
	
	modalClose: function(content) {
		close = new TimelineMax({delay:0, repeat:0, onComplete:function(){ app.dispatcher.dispatch(EVENTS.MODAL.CLOSED); }})
			.fromTo($(content), 0.5, {autoAlpha:1},{autoAlpha:0, force3D:true, ease:Power4.easeOut, delay:0.5}, 'modal')
			.fromTo($(dom.modal.wrapper), 1, {autoAlpha:1},{autoAlpha:0, force3D:true, ease:Power4.easeOut, delay: 0.5}, 'modal')
			.set($(dom.modal.wrapper),{display:'none'})
			.set($(content),{display:'none'})
		;
	},
};